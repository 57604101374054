<template>
    <div class="navigation">
        <div class="title">
            <span> 机构管理/ </span> <span> 机构维护/ </span> <span class="black">学校管理</span>
        </div>
        <el-card class="condition_style2">
            <div class="condition_div">
                <el-form :inline="true" class="flex">
                    <el-form-item>
                        <el-button type="primary" size="small" @click="add('')">新增学校</el-button>
                    </el-form-item>
                    <div>
                        <el-form-item>
                            <el-input v-model="searchForm.schoolName" placeholder="请输入学校名称" suffix-icon="el-icon-search"
                                clearable>
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="searchForm.tel" placeholder="请输入联系电话" suffix-icon="el-icon-search"
                                clearable>
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-cascader class="w_100" v-model="searchForm.area" :options="areaLevelList"
                                :props="{ value: 'id', label: 'name'  }" ref="clearCheckedNodes" placeholder="请选择地区"
                                clearable>
                            </el-cascader>
                        </el-form-item>
                        <el-form-item>
                            <el-select placeholder="请选择学校性质" filterable v-model="searchForm.isPublic" clearable>
                                <el-option v-for="(item, index) in isPublicList" :key="index" :label="item.type"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select placeholder="请选择学校级别" filterable v-model="searchForm.schoolLevel" clearable>
                                <el-option v-for="(item, index) in levelList" :key="index" :label="item.type"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select placeholder="请选择状态" filterable v-model="searchForm.status" clearable>
                                <el-option v-for="(item, index) in typeList" :key="index" :label="item.type"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="small" @click="getList(true)">查询</el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <el-table :data="tableData" style="width: 100%" class="table" :height="tableHeight">
                <el-table-column type="index" label="序号" width="60" align="center" fixed>
                    <template slot-scope="scope">{{scope.$index+(current-1) * size + 1}}</template>
                </el-table-column>
                <el-table-column prop="logo" label="logo" min-width="120" align="center">
                    <template slot-scope="scope">
                        <div class="flex_just_center ">
                            <img class="img_40" :src="scope.row.logo" v-if="scope.row.logo">
                            <div v-else class="icon_div">
                                {{ scope.row.schoolName&&scope.row.schoolName.substring(0, 2) }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="backgroundFile" label="鸟瞰图" min-width="120" align="center">
                    <template slot-scope="scope">
                        <div class="flex_just_center ">
                            <img class="img_40" :src="scope.row.backgroundFile" v-if="scope.row.backgroundFile">
                            <div v-else class="icon_div">
                                {{ scope.row.schoolName&&scope.row.schoolName.substring(0, 2) }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="schoolName" label="学校名称" min-width="120" align="center"></el-table-column>
                <el-table-column prop="eduCode" label="学校唯一编码" min-width="180" align="center"></el-table-column>
                <el-table-column prop="username" label="登录账号" min-width="150" align="center"></el-table-column>
                <el-table-column prop="realName" label="负责人" min-width="180" align="center"></el-table-column>
                <el-table-column prop="tel" label="联系电话" min-width="120" align="center"></el-table-column>
                <el-table-column prop="address" label="详细地址" min-width="250" align="center">
                    <template slot-scope="scope">
                        {{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.districtName}}{{scope.row.address}}
                    </template>
                </el-table-column>
                <el-table-column prop="isPublic" label="学校性质" min-width="80" align="center">
                    <template slot-scope="scope"> {{scope.row.isPublic  | showPublic }}</template>
                </el-table-column>
                <el-table-column prop="schoolLevel" label="学校级别" min-width="80" align="center">
                    <template slot-scope="scope"> {{scope.row.schoolLevel |showLevel}}</template>
                </el-table-column>
                <el-table-column prop="status" label="当前状态" min-width="80" align="center">
                    <template slot-scope="scope"> {{scope.row.status == 1?"禁用":"启用" }}</template>
                </el-table-column>
                <el-table-column prop="updateTime" label="最后操作时间" min-width="200" align="center"></el-table-column>
                <el-table-column fixed="right" label="操作" width="90" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="add(scope.row)" class="ge"> 编辑 </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination :page="current" :size="size" :total="total" @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange"></pagination>
        </el-card>
        <!-- 新增\编辑 -->
        <modal :obj="{
        title: edit ? '编辑学校' : '新增学校',
        dialog: addDialog,
        dialogText: 'addDialog',
        form: addForm,
        ref: 'addForm',
      }" @submit="addSubmit">
            <el-form :model="addForm" label-width="130px" :rules="rules" ref="addForm">
                <div v-if="!edit">
                    <el-form-item label="登录账号：" prop="userName">
                        <el-input v-model="addForm.userName" placeholder="请输入登录账号" @blur="findUserNumber"></el-input>
                    </el-form-item>
                    <el-form-item label="真实姓名：" prop="realName">
                        <el-input v-model="addForm.realName" placeholder="请输入真实姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="密码：" prop="password">
                        <el-input v-model="addForm.password" type="password" placeholder="请输入密码"></el-input>
                    </el-form-item>
                </div>
                <el-form-item label="学校名称：" prop="schoolName">
                    <el-input v-model="addForm.schoolName" placeholder="请输入商户名称"></el-input>
                </el-form-item>
                <el-form-item label="学校唯一编码：" prop="eduCode">
                    <el-input v-model="addForm.eduCode" placeholder="请输入授权秘钥"></el-input>
                </el-form-item>
                <el-form-item label="所属市区：" prop="area">
                    <el-cascader class="w_100" v-model="addForm.area" :options="areaLevelList"
                        :props="{ value: 'id', label: 'name'  }" ref="clearCheckedNodes"></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址：" prop="address">
                    <el-input v-model="addForm.address" placeholder="请输入详细地址"></el-input>
                </el-form-item>
                <el-form-item label="学校性质：" prop="isPublic">
                    <el-select placeholder="请选择学校性质" filterable v-model="addForm.isPublic" class="w_100"
                        :disabled="edit?true:false">
                        <el-option v-for="(item, index) in isPublicList" :key="index" :label="item.type"
                            :value="item.id" v-show="index">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="学校级别：" prop="schoolLevel">
                    <el-select placeholder="请选择学校级别" filterable v-model="addForm.schoolLevel" class="w_100"
                        :disabled="edit?true:false">
                        <el-option v-for="(item, index) in levelList" :key="index" :label="item.type" :value="item.id"
                            v-show="index">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="学校状态：" prop="status">
                    <el-select placeholder="请选择状态" filterable v-model="addForm.status" class="w_100">
                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.type" :value="item.id"
                            v-show="index">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="联系电话：" prop="tel">
                    <el-input v-model="addForm.tel" placeholder="请输入联系电话"></el-input>
                </el-form-item>
                <el-form-item label="logo：" prop="logo">
                    <div class="flex">
                        <el-upload :action="action" :show-file-list="false" :on-success="handleAvatarSuccess" 
                            accept=".png,.jpg,.jpeg,.bmp,.webp" :headers="headers">
                            <el-button size="small" type="primary">点击上传</el-button>
                        </el-upload><span class="upload_span_text">请使用JPG / PNG / BMP格式，比例为1:1</span>
                    </div>
                    <img v-if="addForm.logo" :src="addForm.logo" class="w_130" />
                </el-form-item>
                <div style="text-indent: 50px;font-weight: 700;"><span style="color:red">*</span> 鸟瞰图：</div>
                <el-form-item  >
                    <div class="flex">
                        <el-upload :action="action" :show-file-list="false" :on-success="handleAvatarSuccess1" 
                            accept=".png,.jpg,.jpeg,.bmp,.webp" :headers="headers">
                            <el-button size="small" type="primary">点击上传</el-button>
                        </el-upload><span class="upload_span_text">请使用JPG / PNG / BMP格式</span>
                    </div>
                    <img v-if="addForm.backgroundFile" :src="addForm.backgroundFile" class="w_130" />
                </el-form-item>
            </el-form>
        </modal>
    </div>
</template>

<script>
    import pagination from "@/components/pagination";
    import modal from "@/components/modal";
    import {
        areaLevel,
        merchantSchoolList,
        merchantSchoolSave,
        merchantSchoolUpdate,
        merchantSchoolInfo,
        merchantSchoolCheck,
        files1
    } from "@/api/api";
    export default {
        components: {
            pagination,
            modal,
        },
        data() {
            return {
                action: files1.url,
                headers: {
                    Accept: "application/json, text/plain, */*",
                    Authorization: this.$util.getToken("access_token"),
                },
                size: 10, //每页条数
                current: 1, //当前条数
                total: 0,
                searchForm: {
                    schoolName: "",
                    tel: "",
                    area: [],
                    isPublic: "",
                    schoolLevel: "",
                    status: "",
                    mchId: ""
                },
                tableHeight: this.$util.getHeight(310),
                tableData: [],
                isPublicList: [{
                    id: null,
                    type: "全部"
                }, {
                    id: 0,
                    type: "公立"
                }, {
                    id: 1,
                    type: "私立"
                }],
                levelList: [{
                    id: null,
                    type: "全部"
                }, {
                    id: 0,
                    type: "幼儿园"
                }, {
                    id: 1,
                    type: "小学"
                }, {
                    id: 2,
                    type: "初中"
                }, {
                    id: 3,
                    type: "高中"
                }, {
                    id: 4,
                    type: "大学"
                }],
                typeList: [{
                    id: null,
                    type: "全部"
                }, {
                    id: 0,
                    type: "启用"
                }, {
                    id: 1,
                    type: "禁用"
                }],
                areaLevelList: [],
                addDialog: false,
                edit: "",
                addForm: {
                    schoolName: "",
                    userName: "",
                    password: "",
                    realName: "",
                    area: [],
                    eduCode: "",
                    address: "",
                    province: "",
                    city: "",
                    district: "",
                    isPublic: "",
                    schoolLevel: "",
                    status: "",
                    logo: "",
                    tel: "",
                    mchId: "",
                    backgroundFile: ""
                },
                rules: {
                    userName: [{
                        required: true,
                        message: "请输入登录账号"
                    }],
                    realName: [{
                        required: true,
                        message: "请输入真实姓名"
                    }],
                    password: [{
                        required: true,
                        message: "请输入密码"
                    }],
                    schoolName: [{
                        required: true,
                        message: "请输入学校名称"
                    }],
                    eduCode: [{
                        required: true,
                        message: "学校唯一编码"
                    }],
                    area: [{
                        required: true,
                        message: "请选择所属区域"
                    }],
                    isPublic: [{
                        required: true,
                        message: "选择学校性质"
                    }],
                    schoolLevel: [{
                        required: true,
                        message: "选择学校级别"
                    }],
                    status: [{
                        required: true,
                        message: "选择学校状态"
                    }],
                    backgroundFile: [{
                        required: true,
                        message: "选择上传图片",trigger: 'change' 
                    }],
                },
                userName: ""
            };
        },
        computed: {
            poewrMenuIdList() {
                return this.$store.state.poewrMenuIdList ? this.$store.state.poewrMenuIdList : []
            },
        },
        filters: {
            showPublic(val) {
                switch (val) {
                    case 0:
                        return "公立";
                    case 1:
                        return "私立";
                }
            },
            showLevel(val) {
                switch (val) {
                    case 0:
                        return "幼儿园";
                    case 1:
                        return "小学";
                    case 2:
                        return "初中";
                    case 3:
                        return "高中";
                    case 4:
                        return "大学";
                }
            }
        },
        created() {
            this.addForm.mchId = this.$route.params.id
            this.searchForm.mchId = this.$route.params.id
            this.getList()
        },
        mounted() {
            window.addEventListener('resize', () => {
                this.getTableHeight(330)
            })
            this.getAreaFourLevel()
        },
        methods: {
            getTableHeight(val) {
                this.tableHeight = this.$util.getHeight(val)
            },

            getList(val) {
                if (val) {
                    this.current = 1;
                }
                let area = this.searchForm.area
                let len = area.length
                this.searchForm.province = len ? area[0] : ""
                this.searchForm.city = len ? area[1] : ""
                this.searchForm.district = len ? area[2] : ""
                this.$request(merchantSchoolList(this.current, this.size), this.searchForm).then((res) => {
                    if (res.code === 0) {
                        let data = res.data;
                        this.tableData = data.records;
                        this.total = Number(data.total);
                    }
                });
            },
            // 获取地区
            getAreaFourLevel() {
                this.$request(areaLevel).then((res) => {
                    if (res.code === 0) {
                        let list = res.data
                        this.areaLevelList = list
                    }
                });
            },
            //   新增\编辑-弹框
            add(val) {
                this.edit = val;
                this.addDialog = true;
                this.userName = ""
                this.addForm = {
                    schoolName: "",
                    userName: "",
                    password: "",
                    realName: "",
                    area: [],
                    eduCode: "",
                    address: "",
                    province: "",
                    city: "",
                    district: "",
                    isPublic: "",
                    schoolLevel: "",
                    status: "",
                    logo: "",
                    tel: "",
                    mchId: this.addForm.mchId,
                }
                if (this.$refs.clearCheckedNodes) {
                    this.$refs.clearCheckedNodes.$refs.panel.clearCheckedNodes();
                    this.$refs.clearCheckedNodes.$refs.panel.activePath = [];
                }
                if (this.$refs.addForm) {
                    this.$refs.addForm.resetFields();
                }
                if (this.edit) {
                    this.getInfo(val.schoolId);
                }
            },
            // 上传logo
            handleAvatarSuccess(res, file) {
                if (res.code == 0) {
                    let data = res.data
                    this.$util.message("suc", "上传成功");
                    this.addForm.logo = data.url;
                    this.$forceUpdate()
                } else {
                    this.$util.message("err", res.msg);
                }
            },
            // 上传鸟瞰图
            handleAvatarSuccess1(res, file) {
                if (res.code == 0) {
                    let data = res.data
                    this.$util.message("suc", "上传成功");
                    this.addForm.backgroundFile = data.url;
                    this.$forceUpdate()
                } else {
                    this.$util.message("err", res.msg);
                }
            },
            // 详情
            getInfo(id) {
                this.$request(merchantSchoolInfo, {
                    schoolId: id,
                    mchId: this.addForm.mchId
                }).then((res) => {
                    console.log(res, 55)
                    if (res.code == 0) {
                        this.addForm = res.data
                        this.userName = this.addForm.userName
                        this.addForm.area = (this.addForm.province + "," + this.addForm.city + "," + this
                                .addForm.district)
                            .split(',')
                    }
                });
            },
            findUserNumber() {
                if (this.userName == this.addForm.userName || !this.addForm.userName) return
                this.$request(merchantSchoolCheck, {
                    userName: this.addForm.userName,
                    mchId: this.searchForm.mchId
                }).then((res) => {
                    if (res.code === 0) {
                        let data = res.data;
                        if (data) {
                            this.addForm.userName = this.userName || "";
                            this.$util.message("err", "该账户已存在");
                        }
                    }
                })
            },
            //   新增编辑-上传接口
            addSubmit() {
                let url = this.edit ? merchantSchoolUpdate : merchantSchoolSave;
                this.$refs.addForm.validate((result) => {
                    if(result){
                        if(!this.addForm.backgroundFile){
                            this.$message({
                                showClose: true,
                                message: '请上传鸟瞰图',
                                type: 'error'
                            })
                            return
                        }
                        this.addForm.province = this.addForm.area[0]
                        this.addForm.city = this.addForm.area[1]
                        this.addForm.district = this.addForm.area[2]
                        if (result) {
                            this.$request(url, this.addForm).then((res) => {
                                if (res.code == 0) {
                                    if (res.data) {
                                        this.addDialog = false;
                                        this.$util.message("suc", "操作成功");
                                        this.getList();
                                    }
                                }
                            });
                        }
                    }
                });
            },
            handleSizeChange(val) {
                this.size = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.current = val;
                this.getList();
            },
        },
    };
</script>

<style lang="less" scoped>
    .w_130 {
        width: 130px;
        margin-top: 10px;
    }

    .icon_div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 700;
        color: white;
        background-color: rgb(64, 169, 255);
        width: 40px;
        height: 40px;
        border-radius: 40px;
    }
</style>